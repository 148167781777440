<template>
  <div class="ds-control ds-form-item ds-datachoice" :class="[getCss,{omitOpen:omitOpen}]" :id="id">
    <template v-if="simple && readOnly && !isDesign">
      <DsfTextProxy
        v-model="$omitValue"
        v-bind="_self.$props"
        :show-label="false"
        @click="omitChange"
      ></DsfTextProxy>
      <span class="omitText" @click="omitChange">{{ $omitText }}</span>
      <slot name="error">
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </template>
    <template v-else>
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon"
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self"
      >
        {{ $t(label) }}
      </DsfFieldLabel>
      <div
        class="ds-form-block"
        :class="{ hideAutoInput: !showAutoInput }"
        :style="getFormItemBlockMarginLeft()"
        @click="handleFocus"
      >
        <div
          v-if="!readOnly"
          class="ds-datachoice-input"
          :class="{ 'is-disabled': disabled }"
          @click="clickHandler"
        >
          <div v-if="omitOpen && value.length">
            <DsfTextProxy
              v-model="$omitValue"
              v-bind="_self.$props"
              :show-label="false"
            ></DsfTextProxy>
            <span class="omitText">{{ $omitText }}</span>
          </div>
          <div
            v-else
            ref="tagsInput"
            class="ds-datachoice-tags"
            :style="{ width: 'calc(100% - 26px)' }"
          >
            <div class="tags_div" ref="tags" v-show="value.length">
              <el-tag
                v-for="el in value"
                :key="el.value"
                @click.stop
                size="small"
                :closable="true"
                class="el-tag--info"
                :disable-transitions="true"
                @close="removeTag(el)"
              >
                {{ el.text }}
              </el-tag>
            </div>
            <el-autocomplete
              v-if="autoComplete"
              ref="input"
              v-model="inputStr"
              highlight-first-item
              :style="{ width: '100%' }"
              :popper-class="[popupClass].join(' ')"
              :trigger-on-focus="triggerOnFocus"
              :debounce="delay"
              :fetch-suggestions="querySearch"
              @select="selectHandler"
              @focus="handleFocus"
              @blur="handleBlur"
            >
              <template slot-scope="{ item }">
                <slot name="autocompletelist" :item="item">
                  <div>{{ item.text }}</div>
                </slot>
              </template>
            </el-autocomplete>
          </div>
          <span
            class="el-input__suffix"
            @click.stop="openDialog"
            v-if="isDesign || dialogUrl"
          >
            <span class="el-input__suffix-inner">
              <i class="el-input__icon el-icon-search"></i>
            </span>
          </span>
        </div>
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly" @click="omitChange">
          <DsfTextProxy
            v-model="$omitValue"
            v-bind="_self.$props"
            :show-label="false"
          ></DsfTextProxy>
          <span class="omitText">{{ $omitText }}</span>
        </div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfDataChoice",
  mixins: [$mixins.formControl],
  ctrlCaption: "数据选择",
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
    dialogTitle: {
      type: String,
      default: "请选择",
    },
    dialogUrl: {
      type: String,
      default: "",
    },
    dialogExtendParams: {
      default: () => ({}),
    },
    dialogWidth: {
      type: String,
      default: "800px",
    },
    dialogHeight: {
      type: String,
      default: "500px",
    },
    dialogTextKey: {
      type: String,
      default: "",
    },
    dialogValueKey: {
      type: String,
      default: "",
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("data-choice-meta-data");
      },
    },
    // 值
    value: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    formatter: {
      type: String,
      default: "textValue^text",
    },
    maxTags: {
      type: Number,
      default: 0,
    },
    // 选择时需要禁用的选择框的id
    excludeIds: {
      type: Array,
      default() {
        return [];
      },
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    metaDataType: {
      type: String,
      default: "0",
    },
    chooseMetaData: {
      type: Object,
      default() {
        return {};
      },
    },
    source: {
      type: String,
      default: "",
    },
    delay: {
      type: Number,
      default: 300,
    },
    maxItemsNumber: {
      type: Number,
      default: 20,
    },
    popperWid: {
      type: String,
      default: "auto",
    },
    triggerOnFocus: {
      type: Boolean,
      default: false,
    },
    textKey: {
      type: String,
      default: "text",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    omitOpen: {
      //是否开启省略
      type: Boolean,
      default: false,
    },
    omitNum: {
      //大于多少的时候显示省略
      type: Number,
      default: 6,
    },
    omitText: {
      //省略时显示文字 {num}
      type: String,
      default: "等{length}个",
    },
  },
  data() {
    return {
      remoteInputing: false,
      remoteItems: [],
      inputStr: "",
      popupClass: "ds-datachoice-popup" + dsf.uuid(16),
      sqlParams: {},
      sqlUrl: "",
      id: "dsfDataChoice" + dsf.uuid(16),
      db: [{ text: "xxx", value: "xxx" }],
      showAutoInput: false,
      timer: null,
      omit: false
    };
  },
  created() {
    this.sqlUrl = this.source;
    if (!this.isDesign && this.autoComplete) {
      // 哪个字段作为text,哪个字段作为value，查询哪些字段
      let params = {},
        source = this.$replace(this.source, this);
      if (this.metaDataType * 1) {
        let chooseMetaData = this.chooseMetaData || {};
        if (chooseMetaData.type === "meta") {
          // 元数据
          source = "meta/tree/byName";
          params = {
            name: chooseMetaData.code.id,
            namespace: this.$vm ? this.$vm.nameSpace : "", //dsfa.rm
            pageName: this.$vm ? this.$vm.pageName : "", //main
            order: "[]",
          };
        } else if (chooseMetaData.type === "ds") {
          // 数据源
          source = "meta/tree/dbSource";
          params = {
            rmId: chooseMetaData.code,
          };
        }
      }

      dsf.mix(this.sqlParams, params, this.queryString);
      this.sqlUrl = source;

      if (this.popperWid && this.popperWid !== "auto") {
        let styleDom = document.createElement("style");
        document.body.append(styleDom);
        this.styleDom = styleDom;
        this.styleDom.innerHTML = `
          #${this.id} .el-autocomplete-suggestion{
            width: ${this.popperWid} !important;
          }
        `;
      }
    }
  },
  computed: {
    $omitValue() {
      if (this.omitOpen && !this.omit) {
        return this.value.slice(0,this.omitNum);
      } else {
        return this.value;
      }
    },
    $omitText() {
      if (this.omitOpen && !this.omit) {
        let length = this.value.length;
        let omitNum = this.omitNum
        let num = length - omitNum;
        return num > 0 ? this.omitText.replaceAll("{num}",num).replaceAll("{length}",length).replaceAll("{omitNum}",omitNum) : "";
      } else {
        return "";
      }
    },
    selectValue: {
      get() {
        let v = this.value;
        if (dsf.isDef(v)) {
          return v.map((s) => s.value);
        } else {
          return [];
        }
      },
      set(v) {
        let value = [];
        _.each(v, (el) => {
          if (dsf.type(el) == "string") {
            value.push({
              text: el,
              value: el,
            });
          }
          if (dsf.hasOwn(el, "text") && dsf.hasOwn(el, "value")) {
            value.push(el);
          }
        });
        this.emitValueChange(value);
      },
    },
    options() {
      if (!this.remoteInputing) {
        return this.items;
      } else {
        return this.remoteItems;
      }
    },
  },
  mounted() {
    this.formatValue();
    if (this.$refs.input) {
      let textbox = this.$refs.input.$el.querySelector("input");
      textbox.addEventListener("keydown", (evt) => {
        // var i=this.$refs.input;
        if (evt.keyCode === 8) {
          if (!this.inputStr) {
            let latTag = this.value[this.value.length - 1];
            this.removeTag(latTag);
          }
        }
      });
    }
  },
  updated() {},
  destroyed() {
    this.styleDom?.remove();
    this.timer && clearTimeout(this.timer);
  },
  watch: {
    value: {
      handler(v) {
        this.formatValue();
        let nv = this.value;
        if (!nv) {
          this.$nextTick(() => {
            this.$dispatch("change");
            this.$nextTick(() => {
              this.emitFormValidate(this.value);
            });
          });
        }
      },
    },
  },
  methods: {
    formatValue() {
      let v = this.value;
      let nv = v;
      if (dsf.isUnDef(v)) {
        nv = [];
      } else if (dsf.type(v) === "string" && !v) {
        nv = [];
      } else if (dsf.type(v) === "object" && !v.value) {
        nv = [];
      } else if (!$.isArray(v)) {
        nv = [v];
      }
      if (nv != this.value) {
        this.emitValueChange(nv);
      }
    },
    //点击后自动聚焦到文本框
    clickHandler() {
      if (this.disabled) {
        return;
      }
      //没有自动联想的情况下点击空白处弹出选择框
      if (!this.autoComplete) {
        this.openDialog();
        return;
      }
      this.$refs.input?.focus();
    },
    inputChangeHandler() {
      this.$nextTick(() => {
        this.$dispatch("change");
        this.emitFormValidate(this.value);
      });
    },
    getChoiceParams() {
      let dialogExtendParams = this.dialogExtendParams;
      if (dsf.type(this.dialogExtendParams) == "function") {
        dialogExtendParams = this.dialogExtendParams();
      }
      return {
        value: this.value,
        maxTags: this.maxTags,
        local: this.local,
        dialogExtendParams,
      };
    },
    openDialog() {
      if (this.disabled || !this.dialogUrl) {
        return;
      }
      this.remoteInputing = false;
      let _this = this;
      this.$openDialog({
        title: this.dialogTitle,
        width: this.dialogWidth,
        height: this.dialogHeight,
        top: "10vh",
        params: {
          //标记一下当前页面来自于数据选择
          fromDataChioce: true,
          //页面路径
          path: this.$replace(this.dialogUrl),
          //数据选择的值
          choiceParams: _this.getChoiceParams(),

          selectValue: _this.value,
          excludeIds: this.excludeIds,
          dialogExtendParams: this.dialogExtendParams,
        },
        btns: [
          {
            text: "确定",
            handler(res) {
              if (res.content) {
                let vm = res.content;
                if (vm && vm.yes && dsf.isFunction(vm.yes)) {
                  let returnValue = vm.yes();
                  if (!returnValue) {
                    return false;
                  } else {
                    returnValue = JSON.parse(JSON.stringify(returnValue));
                    let result = _this.mapData(returnValue);
                    var value = _this.value || [];
                    _this.addTags(result);
                    _this.$dispatch("selected", returnValue);
                    _this.$nextTick(() => {
                      _this.$dispatch("change", _this.value);
                    });
                  }
                }
              }
            },
          },
          {
            text: "取消",
          },
        ],
      });
      // this.$alert({
      //   "message":"sdfasdfadsf"
      // }).then(()=>{
      //   _this.addTags(this.db);
      //   _this.$dispatch("selected", this.db);
      //   _this.$nextTick(() => {
      //     _this.$dispatch("change", _this.value);
      //   });
      // })
    },
    mapData(result) {
      if (!this.mapHandler) {
        let arr = dsf.type(result) == "array" ? result : [result];
        //默认取字段名称name、_id作为组件的text、value;
        let list = [];
        list.splice(0, list.length);
        let newRes = [];
        for (let i = 0; i < arr.length; i++) {
          let r = arr[i];
          let value = this.autoMapDataText(r);
          if (value) {
            list.push(value);
            newRes.push(r);
          }
        }
        this.$dispatch("sure", {
          value: list,
          data: newRes,
        });
        return list;
        // this.emitValueChange(list);
      }
    },
    autoMapDataText(data) {
      let rmetakey = /(\w+)\.(\w+)/g;
      //默认检测text 和 name作为text属性
      //默认检测_id 和 value作为value属性
      let commonTextKeys = [
        "text",
        "_text",
        "name",
        "_name",
        "label",
        "_label",
      ];
      let commonValueKeys = ["value", "_value", "id", "_id", "key", "_key"];
      let result = null;
      let text, value;
      for (let k in data) {
        let key = k;
        rmetakey.lastIndex = 0;
        let match = rmetakey.exec(k);
        if (match && match[2]) {
          key = match[2];
        }
        if (
          this.dialogTextKey &&
          (key == this.dialogTextKey || k == this.dialogTextKey)
        ) {
          text = data[k];
        }
        if (
          this.dialogValueKey &&
          (key == this.dialogValueKey || k == this.dialogValueKey)
        ) {
          value = data[k];
        }
        if (dsf.isUnDef(text) && commonTextKeys.indexOf(key) >= 0) {
          text = data[k];
        }
        if (dsf.isUnDef(value) && commonValueKeys.indexOf(key) >= 0) {
          value = data[k];
        }
      }

      if (dsf.isDef(text) || dsf.isDef(value)) {
        result = {
          text: text,
          value: value,
        };
      }
      return result;
    },
    addTags(tags, isAutoComplete) {
      // let list = this.value || [];
      let list = [];
      if (isAutoComplete) {
        list = this.value || [];
        if (this.maxTags && this.maxTags > 0 && list.length >= this.maxTags) {
          dsf.warn("超出数据：" + JSON.stringify(tags));
          dsf.layer.message("已超出数量上限【" + this.maxTags + "】", false);
          return;
        } else {
          let _list = _.differenceBy(list, [tags], "value");
          _list.push(tags);
          list = _list;
        }
      } else {
        tags = dsf.type(tags) == "array" ? tags : [tags];
        _.each(tags, (tag, i) => {
          if (this.maxTags && this.maxTags > 0 && list.length >= this.maxTags) {
            dsf.warn("超出数据：" + JSON.stringify(tag));
            dsf.layer.message("已超出数量上限【" + this.maxTags + "】", false);
            return false;
          }
          if (dsf.type(tag) == "string") {
            tag = {
              text: tag,
              value: tag,
            };
          }
          let index = _.findIndex(list, (el) => tag.value === el.value);
          if (index < 0) {
            list.push(tag);
          }
        });
      }
      this.emitValueChange(list);
      this.$nextTick(() => {
        this.emitFormValidate(list);
      });
    },
    removeTag(tag) {
      let index = _.findIndex(this.value, (el) => {
        return el.value === tag.value;
      });
      if (index >= 0) {
        this.value.splice(index, 1);
        this.emitValueChange(this.value);
        this.$dispatch("change", this.value);
        this.$nextTick(() => {
          this.emitFormValidate(this.value);
        });
      }
    },
    querySearch(queryString, cb) {
      const self = this;
      const url = this.$replace(self.sqlUrl, this);
      self.sqlParams.q = queryString;
      self.$http
        .get(url, self.sqlParams)
        .done((res) => {
          if (!res?.success) {
            dsf.layer.message(res?.message || "自动联想接口异常", false);
            cb([]);
            return;
          }
          let reslut = [];
          _.forEach(res.data.slice(0, self.maxItemsNumber), (item) => {
            if (item[self.valueKey]) {
              reslut.push({
                text: item[self.textKey],
                value: item[self.valueKey],
                ...item,
              });
            }
          });
          cb(reslut);
        })
        .error((err) => {
          dsf.layer.message("自动联想接口异常", false);
          cb([]);
        });
    },
    //联想选中
    selectHandler(item) {
      if (item) {
        this.inputStr = "";
        this.addTags(item, true);
        this.$dispatch("select-change",item)
      }
    },
    handleFocus() {
      this.showAutoInput = true;
    },
    handleBlur() {
      if (!this.inputStr) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.showAutoInput = false;
        }, 300);
      }
    },
    omitChange(){
      if(this.omitOpen){
        this.omit = !this.omit;
      }
    }
  },
});
</script>
