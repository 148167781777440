<script>
export default dsf.component({
  name: "DsfDataGridColumn",
  inheritAttrs: false,
  mixins: [$mixins.layout],
  ctrlCaption: "表格列",
  props: {
    column: {
      type: Object,
      default() {
        return {
          label: "",
          children: []
        };
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    sortable: {
      type: [Boolean, String],
      default: false
    }
  },
  inject: {
    $list: {
      default: null
    }
  },
  data() {
    return {
      editable: false
    };
  },
  created() {},
  computed: {
    ownerTable() {
      let parent = this.elTable;
      while (parent.$options.name != "DsfDataGridTable") {
        parent = parent.$parent;
      }
      return parent;
    },
    elTable() {
      return this.$parent;
    },
    realColumns() {
      return this.ownerTable.realColumns;
    },
    filterColumns() {
      return this.ownerTable.filterColumns;
    }
  },
  destroyed() {
    // console.log("列被销毁")
  },
  beforeMount() {},
  mounted() {},
  methods: {
    clickHandler(evt) {
      let list = this.$list;
      if (!list.isDesign) {
        let _this = this;
        if (this.column.filterInfo) {
          let filterInfo = this.column.filterInfo;
          let mkey = filterInfo.field;
          let type = filterInfo.type;
          let dataSource = filterInfo.dataSource;
          let currentFilter = filterInfo.value;
          let dialogHeight = "50vh";
          if (type) {
            dialogHeight = type == "number" || type == "ymd" || type == "ym" ? "30vh" : dialogHeight;
            this.$openDialog({
              title: "筛选过滤",
              content: "DsfColumnDataFileter",
              width: "400px",
              height: dialogHeight,
              overflow: "hidden hidden",
              params: {
                list: list,
                type: type,
                field: mkey,
                dataSource: dataSource,
                currentFilter: currentFilter || null
              },
              yes: (res) => {
                this.getFilterValue(filterInfo, res.yes());
                this.$nextTick(() => {
                  list.reloadData(true);
                });
              },
              btns: [
                {
                  text: "确定",
                  handler: (res) => {
                    this.getFilterValue(filterInfo, res.yes());
                    this.$nextTick(() => {
                      list.reloadData(true);
                    });
                  }
                },
                {
                  text: "清除过滤",
                  handler: (res) => {
                    this.$set(filterInfo, "value", null);
                    this.$nextTick(() => {
                      list.reloadData(true);
                    });
                  }
                },
                {
                  text: "取消"
                }
              ],
              closed() {
                mkey = null;
                type = null;
                dataSource = null;
                currentFilter = null;
                filterInfo = null;
                _this = null;
              }
            });
          }
        }
      }
      evt.stopPropagation();
    },
    getFilterValue(filterInfo, v) {
      this.$set(filterInfo, "value", v);
    },
    getWidth(column) {
      let width = column.width;
      let tableWidth = this.ownerTable.tableClientWidth;
      width = this.ownerTable.percentageToPx(width, tableWidth);
      return width;
    }
  },
  render(h) {
    return renderHeader.call(this, h, this.column, this.$vnode.data.key);
  }
});

function renderHeader(h, column, key) {
  let _this = this;
  let child = [];
  let scopedSlots = null;
  let sortabled = false;
  let isFilter = false;
  if (column.children && column.children.length > 0) {
    child = _.map(column.children, (c, i) => {
      return renderHeader.call(this, h, c, i);
    });
  } else {
    child = this.$slots[column.columnId];
    sortabled = this.ownerTable.getColumnSort(column);
    isFilter = column.isLast && column.isFilter && column?.filterInfo?.type;
    scopedSlots = {
      header(scope) {
        return renderElTableColumnHeader(h, _this, column, scope, sortabled, isFilter);
      },
      default(scope) {
        return _this.$scopedSlots[column.columnId] && _this.$scopedSlots[column.columnId](scope);
      }
    };
    // scopedSlots = _this._u(
    //   [
    //     {
    //       key: "header",
    //       fn(scope) {
    //         return [
    //           //列文字
    //           renderLabel(h, _this, column),
    //           //列排序
    //           sortabled && renderSort(h, _this, column),
    //           //列检索
    //           isFilter && renderFilter(h, _this, column)
    //         ];
    //       }
    //     },
    //     {
    //       key: "default",
    //       fn(scope) {
    //         return [_this._t(column.columnId || "default", null, null, scope)];
    //       }
    //     }
    //   ],
    //   null,
    //   true
    // );
  }
  let props = { ...column };
  // props.minWidth=props.width;
  delete props.resizable;
  delete props.width;
  if (this.ownerTable.isDesign) {
    delete props.fixed;
  }
  props.columnKey = column.columnId;
  let isResize = !this.ownerTable.isDesign ? (!this.ownerTable.isTreeColumns ? true : false) : false;

  let data = {
    key: key,
    props: props,
    attrs: {
      ...this.$attrs,
      minWidth: props.minWidth,
      width: this.getWidth(column),
      // sortable: this.sortable,
      sortable: sortabled,
      className: column.hide ? "ds-datagrid-column-hide" : "",
      resizable: isResize,
      "column-id": column.columnId
      // property:column.columnId
    }
  };
  // delete data.props.width;
  if (scopedSlots) {
    data.scopedSlots = scopedSlots;
  }
  if (this.ownerTable) {
    if (this.ownerTable.isDesign) {
      let vnode = h("el-table-column", data, child);
      return vnode;
    } else {
      if (!column.hide) {
        let vnode = h("el-table-column", data, child);
        return vnode;
      }
    }
  }
  return null;
}
function renderLabel(h, vm, column) {
  return h("span", {
    domProps: {
      innerHTML: `${column.prefix || ""}${vm.$t(column.label)}${column.suffix || ""}`
    }
  });
}
function renderSort(h, vm, column) {
  return h(
    "span",
    {
      staticClass: "caret-wrapper caret-wrapper-proxy"
    },
    [
      h("i", {
        staticClass: "sort-caret ascending"
      }),
      h("i", {
        staticClass: "sort-caret descending"
      })
    ]
  );
}
function renderFilter(h, vm, column) {
  return h(
    "span",
    {
      staticClass: "el-table__column-filter-trigger",
      on: {
        click: vm.clickHandler
      }
    },
    [
      h("i", {
        staticClass: "iconfont ",
        class: {
          "icon-filter-full": column?.filterInfo?.value,
          "icon-filter": !column?.filterInfo?.value
        }
      })
    ]
  );
}

function renderTextContent(h, vm, column) {
  return h("dsf-text-content-editable", {
    model: {
      value: column.label,
      callback(v) {
        column.label = v;
      }
    },
    on: {
      "update:editable"(v) {
        vm.editable = v;
      }
    },
    nativeOn: {
      dblclick(event) {
        this.editable = true;
      }
    },
    props: {
      editable: vm.editable
    }
  });
}

function renderElTableColumnHeader(h, vm, column, scope, sortabled, isFilter) {
  let res = [];
  if (column.required) {
    res.push(
      h("i", {
        staticClass: "required",
        staticStyle: { "margin-right": "5px" }
      })
    );
  }
  let data = {
    staticClass: "ds-table-header-conent"
  };
  if (vm.ownerTable.isDesign) {
    //设计模式附加双击可编辑事件
    data.on = {
      dblclick: (event) => {
        vm.editable = true;
        vm.$nextTick(() => {
          dsf.designer.beginEditTextConent(event.currentTarget);
        });
      }
    };
  }
  let contentChild = [];
  if (!vm.editable) {
    //列文字
    contentChild.push(renderLabel(h, vm, column));
    //列排序
    sortabled && contentChild.push(renderSort(h, vm, column));
    //列检索
    isFilter && contentChild.push(renderFilter(h, vm, column));
  }
  res.push(h("div", data, [vm.editable ? renderTextContent(h, vm, column) : contentChild]));
  return res;
}
</script>
