import focus from "./focus";
import clickoutside from "./clickoutside";
import contextMenu from "./contextMenu";
import './loading'

function install(Vue){
  Vue.use(focus);
  Vue.use(clickoutside);
  Vue.use(contextMenu);
  // Vue.use(loading)
}

export {
  install,
  focus,
  clickoutside,
  // loading
}
export default install;