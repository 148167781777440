var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ds-data-panel-primary-group",style:({ padding: _vm.padding.join('px ') + 'px' })},[_c('div',{staticClass:"ds-data-panel-primary-group-main",style:({
      margin: ("-" + (_vm.spacing / 2) + "px"),
    })},[_vm._l((_vm.itemConfig),function(item,index){return [_vm._t("default",function(){return [_c(_vm.panelModel,_vm._b({key:index,tag:"component",style:({
            width: ("calc((100% - (" + _vm.spacing + "px * " + _vm.justify + ")) / " + _vm.justify + ")"),
            margin: ((_vm.spacing / 2) + "px"),
          }),attrs:{"size":_vm.size,"panel-data":_vm.filterData(item.key) || {},"showShadow":_vm.showShadow,"height":_vm.height,"isGroup":true,"textAlign":_vm.textAlign,"isDesign":_vm.isDesign},scopedSlots:_vm._u([{key:"item",fn:function(scope){return [_vm._t("item",null,null,scope)]}},{key:"itemLeft",fn:function(scope){return [_vm._t("itemLeft",null,null,scope)]}},{key:"itemRight",fn:function(scope){return [_vm._t("itemRight",null,null,scope)]}}],null,true)},'component',item,false))]},null,{
          style: {
            width: ("calc((100% - (" + _vm.spacing + "px * " + _vm.justify + ")) / " + _vm.justify + ")"),
          },
          spacing: _vm.spacing,
          justify: _vm.justify,
          item: item,
          data: _vm.filterData(item.key) || {},
          $index: index,
        })]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }