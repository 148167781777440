<template>
  <div class="dsf-alter-password-control" v-show="!loading">
    <div class="step-box" v-if="!step">
      <p class="title">{{title}}<span>{{notice}}</span></p>
      <input type="password" v-model="newPwd" class="dsf-alter-password-input" placeholder="请输入新密码" />
      <br />
      <input type="password" v-model="confirmPwd" class="dsf-alter-password-input" placeholder="请再次输入新密码" />
      <p class="error-msg" v-if="alterResult">
        <i class="iconfont icon-tixingshixin" />
        {{alterResult}}
      </p>
      <br />
      <el-button type="primary" class="dsf-alter-password-btn" :class="{ disabled: !(newPwd&&newPwd===confirmPwd) }" :disabled="!(newPwd&&newPwd===confirmPwd)" @click="nextStep">
        下一步
      </el-button>
    </div>
    <div class="step-box" v-if="step">
      <div class="step-succss">
        <i class="el-icon-check" />
      </div>
      <p class="success-tip">登录密码修改成功</p>
      <el-button @click="reLogin" type="primary" class="dsf-alter-password-success-btn">
        重新登录
      </el-button>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfPlatformAlterPassword",
  ctrlCaption: "修改密码",
  mixins: [$mixins.control],
  props: {
    title: {
      type: String,
      default: "修改密码"
    },
    alterUrl: {
      type: String,
      default: "oua2/user/pwd/selfupdate"
    },
    // authUrl: {
    //   type: String,
    //   default: "oua2/user/pwd/selfverify"
    // },
    intervalUrl: {
      type: String,
      default: "oua2/user/pwd/send/interval"
    }
  },
  data() {
    return {
      loading: false,
      notice: "密码设置6到20位,必须包含大写字母、小写字母、数字",
      // 是否修改成功
      step: false,
      // 新密码
      newPwd: "",
      // 确认 新密码
      confirmPwd: "",
      // 返回错误结果
      alterResult: ""
    };
  },
  mounted() {
    this.loading=true;
    this.$http
      .get(this.intervalUrl, {})
      .done(({ success, data }) => {
        if (success) {
          this.notice = data.notice;
        }
      })
      .error((err) => {
        dsf.error(err);
      })
      .always(() => {
        this.loading = false;
      });
  },
  methods: {
    nextStep() {
      this.alterPwd();
    },
    alterPwd() {
      this.$http
        .post(this.alterUrl, {param: dsf.sm2.encode(this.newPwd,dsf.config.setting_public_smpublickey)})
        .done(({ success, message }) => {
          if (success) {
            this.step = true;
          } else {
            this.alterResult = message;
          }
        })
        .error((err) => {
          dsf.error(err);
          dsf.layer.message(err?.message || "请求异常", false);
        })
        .always(() => {});
    },
    reLogin() {
      this.$webAPI
        .loginOut()
        .then(({ success, message }) => {
          if (success) {
            dsf.storage.session.clear();

            if (this.$listeners["complete"]) {
              this.$dispatch("complete");
            } else {
              location.replace(dsf.config.setting_public_pc_login_page);
            }
          } else {
            dsf.layer.message(message, false);
          }
        })
        .catch((err) => {
          console.error(err);
          dsf.layer.message("退出失败", false);
        });
    }
  }
});
</script>
