export default {
  install(Vue) {
    Vue.filter("htmlContext", function (v) {
      if (dsf.isDef(v)) {
        return v;
      } else {
        return "";
      }
    });
  }
};
