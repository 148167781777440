<template>
  <div class="ds-control ds-tree-selector">
    <el-row>
      <el-col :span="showRightBox ? 15 : 24">
        <div class="head">
          <el-input v-model="filterText" placeholder="输入关键字进行过滤">
            <i slot="suffix" :class="[!filtering?'iconfont icon-Magnifier':'el-icon-loading']"></i>
          </el-input>
        </div>
        <div class="content">
          <DsfTree
            v-if="slots.length == 1 && !showTab"
            ref="dsfTree"
            tab-index="0"
            :right-istree="typeStyle === 'tree'"
            :show-check-box="typeStyle === 'tree' ? '0' : slots[0].showCheckBox"
            :types="slots[0].types"
            :choose-one-node="slots[0].chooseOneNode"
            :default-open-series="slots[0].defaultOpenSeries"
            :meta-data-type="slots[0].metaDataType"
            :choose-meta-data="slots[0].chooseMetaData"
            :sql-url="slots[0].sqlUrl"
            :is-async="slots[0].async"
            :async-sql-url="slots[0].asyncSqlUrl"
            :show-type="slots[0].showType"
            :show-sub-dept="slots[0].showSubDept"
            :show-level="slots[0].showLevel"
            :is-design="isDesign"
            :node-key="slots[0].nodeKey"
            :excludeIds="excludeIdsAll"
            :valueNodeKey="valueNodeKey"
            :searchSqlUrl="slots[0].searchSqlUrl"
            :filterKeyInfo="filterKeyInfo"
            :max-num="getMaxLength()"
            :pagination="slots[0].pagination"
            :page-size="slots[0].pageSize"
            :pagination-sql="slots[0].paginationSql"
            :choose-one-node-special="slots[0].chooseOneNodeSpecial"
            :click-text-fun="slots[0].clickTextFun"
            @data-loaded="treeLoadData"
            @set-treeobj="setTreeObj"
            @delete-selectvalue="deleteSelectValue"
            @set-selecteddata="setSelectedData"
            @check-node="checkNode"
            @filterState="filterState"
          />

          <el-tabs
            v-else-if="slots.length > 1 || (slots.length == 1 && showTab)"
            v-model="activeName"
            type="border-card"
          >
            <el-tab-pane
              v-for="(d, i) in slots"
              :ref="d.name"
              :key="d.name"
              :label="d.title"
              :name="d.title"
              :lazy="isDesign ? false : true"
            >
              <DsfTree
                ref="dsfTree"
                :tab-index="i"
                :show-check-box="
                  slots.length == 1 && typeStyle === 'tree'
                    ? '0'
                    : d.showCheckBox
                "
                :right-istree="typeStyle === 'tree'"
                :types="d.types"
                :choose-one-node="d.chooseOneNode"
                :default-open-series="d.defaultOpenSeries"
                :meta-data-type="d.metaDataType"
                :choose-meta-data="d.chooseMetaData"
                :sql-url="d.sqlUrl"
                :is-async="d.async"
                :async-sql-url="d.asyncSqlUrl"
                :show-type="d.showType"
                :show-sub-dept="d.showSubDept"
                :show-level="d.showLevel"
                :is-design="isDesign"
                :node-key="d.nodeKey"
                :excludeIds="excludeIdsAll"
                :valueNodeKey="valueNodeKey"
                :searchSqlUrl="d.searchSqlUrl"
                :filterKeyInfo="filterKeyInfo"
                :max-num="getMaxLength()"
                :pagination="d.pagination"
                :page-size="d.pageSize"
                :pagination-sql="d.paginationSql"
                :choose-one-node-special="d.chooseOneNodeSpecial"
                :click-text-fun="d.clickTextFun"
                @data-loaded="treeLoadData"
                @set-treeobj="setTreeObj"
                @delete-selectvalue="deleteSelectValue"
                @set-selecteddata="setSelectedData"
                @check-node="checkNode"
                @filterState="filterState"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col v-if="showRightBox" :span="9">
        <div class="head el-row">
          <el-col
            v-if="!(slots.length == 1 && typeStyle === 'tree')"
            :span="12"
            class="ellipsis"
          >
            已选择
            <b class="chooseNum" v-text="selectedData.length"></b>
          </el-col>
          <el-col
            :span="slots.length == 1 && typeStyle === 'tree' ? 24 : 12"
            class="ellipsis"
          >
            <span @click="resetSelect">
              <i class="el-icon-refresh-right"></i>重置
            </span>
          </el-col>
        </div>
        <div class="content">
          <el-tree
            v-if="slots.length == 1 && typeStyle === 'tree'"
            ref="tree"
            :props="rightTree.props"
            :data="rightTree.data"
            :render-content="renderRightTreeContent"
            :default-expand-all="true"
            :node-key="rightTree.nodeKey"
          />
          <ul v-else class="list" :class="{ canSort: getSort() }">
            <li v-for="(li, i) in selectedData" :key="li._id">
              {{ i + 1 }}. {{ li._name }}
              <span>
                <i
                  v-if="getSort() && i != 0"
                  class="el-icon-top"
                  title="上移"
                  @click="up(i)"
                ></i>
                <i
                  v-if="getSort() && i < selectedData.length - 1"
                  class="el-icon-bottom"
                  title="下移"
                  @click="down(i)"
                ></i>
                <i class="el-icon-close" title="删除" @click="deleted(li)"></i>
              </span>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfTreeSelector",
  ctrlCaption: "树选择器",
  mixins: [$mixins.control, $mixins.dataResult],
  props: {
    slots: {
      type: Array,
      default: function () {
        return [];
      },
    },
    sort: {
      //已选数据是否可排序
      type: Boolean,
      default: false,
    },
    typeStyle: {
      //展现样式，list列表式、tree树结构
      type: String,
      default: "list",
    },
    maxLength: {
      //最大选择数量
      type: Number,
      default: 0,
    },
    showTab: {
      //只有一组数据的时候是否呈现标签样式
      type: Boolean,
      default: true,
    },
    showRightBox: {
      //是否选中右侧区域
      type: Boolean,
      default: true,
    },
    nodeKey: {
      //数据标识，部门选人组件默认是globalId
      type: String,
      default: "_id",
    },
    excludeIds: {
      //禁选节点的数据标识值 .is_excluded
      type: Array,
      default: function () {
        return [];
      },
    },
    valueNodeKey: {
      //返回的value对应的节点标识
      type: String,
      default: "_id",
    },
    filterKeyInfo: {
      // 查询条件
      type: Array,
      default() {
        return [
          {
            code: "_name",
            operation: "like"
          }
        ];
      }
    },
  },
  data() {
    /**
     * activeName: 当前选中的Tab的名称，只用于初始化打开的tab没有其余处理
     * filterText: 搜索的值
     * treesObj: 对应了每个子组件tree.vue中的element-tree的实例
     * treeSelected: 对应了每个子组件tree.vue中选中的数据
     * selectedData: 用于存储右侧选中区域显示的值
     * defaultSelect: 进入组件默认选中的集合
     */
    return {
      activeName: "",
      filterText: "",
      treesObj: [],
      treeSelected: [],
      selectedData: [],
      defaultSelect: [],
      rightTree: {
        data: [],
        props: {
          children: "children",
          label: "_name",
        },
        nodeKey: "",
        keysOfNode: {},
      },
      isCheckNodeCtrl: false,
      filtering: false
    };
  },
  watch: {
    filterText(val) {
      this.filterState(true);
      for (let i in this.treesObj) {
        if(this.treesObj[i]){
          if (dsf.type(this.$refs.dsfTree) === "array") {
            this.$refs.dsfTree[i].filter(val);
          } else {
            this.$refs.dsfTree.filter(val);
          }
        }
      }
    },
  },
  computed: {
    excludeIdsAll() {
      // 禁选的节点_id 集合
      return this.excludeIds.concat(this.$vm?.$attrs?.excludeIds || []);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (
        !dsf.isEmptyObject(this.$choiceParams) &&
        _.isArray(this.$choiceParams.slots) &&
        this.$choiceParams.slots.length > 0
      ) {
        // 如果是“部门人员”控件配置了数据，则使用“部门人员”的配置
        this.slots.length = this.$choiceParams.slots.length;
        for (let i in this.$choiceParams.slots) {
          this.slots[i] = this.$choiceParams.slots[i];
        }
      }
      this.rightTree.nodeKey = this.slots?.[0]?.nodeKey || this.nodeKey;
      this.initConfig();
      this.initDefaultSelect();
    },
    initConfig() {
      this.treesObj = [];
      this.treeSelected = [];
      this.selectedData = [];

      _.forEach(this.slots, (slot, index) => {
        if (index == 0) this.activeName = slot.title;
        this.treeSelected.push([]);
        this.treesObj.push(null)
      });
    },
    initDefaultSelect() {
      let _selectValue = this.$vm?.$attrs?.selectValue;
      if (dsf.type(_selectValue) == "array") {
        let selectValue = [];
        _selectValue.forEach(({ value, text }) => {
          if (value && text) {
            let obj = {
              [this.valueNodeKey]: value,
              _name: text,
            };
            if (this.valueNodeKey == "globalId") {
              obj._id = _.last(value.split("."));
            }
            selectValue.push(obj);
          }
        });
        this.defaultSelect = selectValue;
      }
      this.selectedData = this.defaultSelect;
    },
    treeLoadData({ target, args }) {
      let _this = this;
      if (args.length && _this.defaultSelect.length) {
        target.$nextTick(() => {
          let tree = target.$refs.tree;
          tree.$nextTick(() => {
            if (_this.slots.length == 1 && _this.typeStyle === "tree") {
              let defaultSelect = [];
              _.forEach(_this.defaultSelect, (obj) => {
                let node = tree.getNode(obj[target.nodeKey]);
                if (node) {
                  let obj = node.data;
                  let _globalId = obj.globalId.split(".");
                  if (!obj.treeinfo_pid && _globalId.length >= 2) {
                    obj.treeinfo_pid = _globalId[_globalId.length - 2];
                  }
                  defaultSelect.push(dsf.mix({}, obj));
                }
              });

              let temp1 = {},
                treed = [];
              for (let i = 0; i < defaultSelect.length; i++) {
                let node = defaultSelect[i];
                node.children = [];
                temp1[node._id] = node;
                if (temp1[node.treeinfo_pid]) {
                  if (!temp1[node.treeinfo_pid]["children"]) {
                    temp1[node.treeinfo_pid]["children"] = [];
                  }
                  temp1[node.treeinfo_pid]["children"].push(node);
                } else {
                  treed.push(node);
                }
              }
              _this.rightTreeData(treed);
            } else {
              _.forEach(_this.defaultSelect, (nodeData) => {
                _this.changeNodeCheckState(target, tree, nodeData, true);
              });
            }
          });
        });
      }
    },
    changeNodeCheckState(tree, treeObj, nodeData, state) {
      // 切换nodeData节点,在tree tab中的状态为state
      let _id = nodeData._id || nodeData.globalId;
      let attr =
        "[nodekey" +
        (tree.nodeKey == "globalId" ? "$" : "") +
        "='" +
        _id +
        "']";
      let nodes = tree.$el.querySelectorAll(attr);
      _.each(nodes, (el) => {
        let nodeEl = el.closest(".el-tree-node");
        let node = nodeEl ? nodeEl.__vue__ : null;
        if (node) {
          treeObj.setChecked(
            node.node,
            state,
            tree.chooseOneNode == "0" ? false : true
          );
        }
      });
    },
    setSelectedData(opts) {
      //切换树节点的选中状态时 改变右侧选择数据区域集合
      let tabIndex = -1;
      if (opts) {
        if (this.slots.length == 1 && this.typeStyle === "tree") {
          this.setRightTree(opts);
          return;
        }
        tabIndex = opts.args.tabIndex;
        this.treeSelected[tabIndex] = opts.args.data;
      }

      if (this.getMaxLength() * 1 === 1) {
        this.selectedData = opts?.args?.data || [];
        _.forEach(this.treeSelected, (d, i) => {
          if (i != tabIndex) {
            this.treeSelected[i] = [];
            let tree1 = this.treesObj[i];
            if(tree1){
              _.forEach(tree1?.getCheckedNodes() || [],(t)=>{
                if(opts?.args?.data?._id != t._id){
                  tree1?.setChecked(t,false);
                }
              })
              let tree2 = tree1?.$parent?.$refs?.tree2
              if(tree2){
                _.forEach(tree2?.getCheckedNodes() || [],(t)=>{
                  if(opts?.args?.data?._id != t._id){
                    tree2?.setChecked(t,false);
                  }
                })
              }
            }
          }
        });
      } else {
        let datas = [].concat(...this.treeSelected, this.defaultSelect);
        // 去重
        datas = _.unionBy(datas, "_id");

        if (this.getSort()) {
          // 留住排序
          let selectedData = [];
          _.forEach(this.selectedData, (d) => {
            for (let i = 0; i < datas.length; i++) {
              let _d = datas[i];
              if (d[this.valueNodeKey] == _d[this.valueNodeKey]) {
                selectedData.push(_d);
                datas.splice(i, 1);
                i--;
              }
            }
          });
          this.selectedData = [].concat(selectedData, datas);
        } else {
          this.selectedData = datas;
        }
      }
    },
    changeNodeCheckStateAll(nodeData, state, reset) {
      // 切换nodeData节点在所有tab中的状态为state
      _.each(this.treesObj, (treeObj, i) => {
        if(treeObj){
          let tree = "";
          if (dsf.type(this.$refs.dsfTree) === "array") {
            tree = this.$refs.dsfTree[i];
          } else {
            tree = this.$refs.dsfTree;
          }
          if(!state && nodeData.isSearch && i==nodeData.tabIndex){
            // 删除搜索的值
            tree.deleteSearchCheckedNodes(nodeData[tree.nodeKey]);
          }
          this.changeNodeCheckState(tree, treeObj, nodeData, state);
          if (!reset) {
            //如果不是重置，才获取最新选择数据
            this.treeSelected[i] = tree.getSelectedData(
              [].concat(treeObj.getCheckedNodes(),),
            );
          }
        }
      });
    },
    deleted(nodeData) {
      // 右侧选中数据展示区域，单删除
      this.changeNodeCheckStateAll(nodeData, false);
      this.deleteSelectValue(nodeData);
      this.setSelectedData();
    },
    deleteSelectValue(nodeData) {
      // 默认被选中的数据取消了选中状态特殊处理
      if (nodeData.args) {
        nodeData = nodeData.args;
      }
      this.spliceSelect("defaultSelect", nodeData);
      this.spliceSelect("selectedData", nodeData);
    },
    spliceSelect(key, nodeData) {
      let index = this[key].findIndex(
        (obj) => obj[this.valueNodeKey] == nodeData[this.valueNodeKey]
      );
      if (index > -1) {
        this[key].splice(index, 1);
      }
    },
    resetSelect() {
      // 解决重置后还留有默认带来的数据bug
      this.defaultSelect = [];
      // 清空所有选择的数据-考虑性能问题注释
      // _.forEach(this.selectedData, (nodeData) => {
      //   this.changeNodeCheckStateAll(nodeData, false, true);
      // });

      let deletedDatas = dsf.mix([],this.selectedData)
      _.forEach(deletedDatas,(li)=>{
        this.deleted(li);
      })
      this.selectedData = [];
      // for (let i in this.treesObj) {
      //   this.treeSelected[i] = [];
      //   this.treesObj[i].setCheckedKeys([]);
      // }
      if (this.slots.length == 1 && this.typeStyle === "tree") {
        this.rightTree.data = [];
      }
    },
    reloadData() {
      this.resetSelect();
      this.treesObj = [];
      this.treeSelected = [];
      if (this.slots.length == 1 && this.typeStyle === "tree") {
        this.rightTree.data = [];
      }

      let dsfTree = this.$refs.dsfTree;
      if (dsf.type(dsfTree) === "array") {
        dsfTree.forEach((t) => t.reloadData());
      } else {
        dsfTree.reloadData();
      }
    },
    getSort() {
      if (this.$choiceParams && dsf.isDef(this.$choiceParams.sort)) {
        return this.$choiceParams.sort;
      }
      return this.sort;
    },
    getMaxLength() {
      if (this.$choiceParams && dsf.isDef(this.$choiceParams.maxLength)) {
        return this.$choiceParams.maxLength;
      }
      return this.maxLength;
    },
    setTreeObj(opts) {
      // 右侧的选择数据被删除时，需要同步修改树节点的选择状态
      let treeVm = opts.target;
      this.treesObj[treeVm.tabIndex] = treeVm.$refs.tree;
      treeVm.filterText = this.filterText;
    },
    up(i) {
      // 上移
      if (i > 0) {
        let curr = this.selectedData[i];
        let currIndex = i;
        dsf.array.remove(this.selectedData, curr);
        this.selectedData.splice(currIndex - 1, 0, curr);
      }
    },
    down(i) {
      // 下移
      if (this.selectedData.length - 1 > i) {
        let curr = this.selectedData[i];
        let currIndex = i;
        dsf.array.remove(this.selectedData, curr);
        this.selectedData.splice(currIndex + 1, 0, curr);
      }
    },
    getReturnValue() {
      const self = this;
      let datas = [];
      if (
        this.getMaxLength() * 1 > 0 &&
        this.selectedData.length > this.getMaxLength() * 1
      ) {
        dsf.layer.message(
          "已超出选择数量上限【" + this.getMaxLength() + "】",
          false
        );
        return false;
      }
      for (let i in this.selectedData) {
        let obj = {};
        obj = {
          value: self.selectedData[i][this.valueNodeKey],
          text: self.selectedData[i]._name,
        };
        let index = _.findIndex(datas, (it) => {
          return it.value == obj.value;
        });
        if (index < 0) {
          datas.push(dsf.mix(obj, this.selectedData[i]));
        }
      }
      return datas;
    },
    renderRightTreeContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span style="margin-left:16px">
            <i
              class="el-icon-close"
              title="删除"
              on-click={() => this.removeNode(node, data)}
            ></i>
          </span>
        </span>
      );
    },
    removeNode(node, data) {
      this.$refs.tree.remove(node);
      let deletes = [];
      this.removeNodeChild(deletes, data);
      this.selectedData = _.differenceBy(this.selectedData, deletes, "_id");
    },
    removeNodeChild(arr, d) {
      arr.push(d);
      _.forEach(d.children, (n) => {
        this.removeNodeChild(arr, n);
      });
    },
    setRightTree(opts) {
      let node = opts.args.data;
      let data = dsf.mix({}, node.data);
      if (this.slots[0].chooseOneNode == "0") {
        data["children"] = [];
      }

      let nodePath = dsf.mix(true, [], this.treesObj[0].getNodePath(node));
      this.rightTreeData(nodePath);
    },
    rightTreeData(nodePath) {
      let temp;
      let loaded = false;
      _.forEachRight(nodePath, (node, i) => {
        let _node = this.$refs.tree.getNode(node);
        if (_node) {
          if (temp) this.$refs.tree.append(temp, _node);
          loaded = true;
          return false;
        } else {
          if (temp) {
            node.children = [temp];
          }
          temp = node;
        }
      });
      if (!loaded) {
        this.rightTree.data = [temp];
      }

      let datas = [].concat(this.selectedData, nodePath);
      // 去重
      datas = _.unionBy(datas, "_id");

      this.selectedData = datas;
    },
    checkNode(opts) {
      this.$dispatch("getCheckNode", opts);
    },
    filterState(state){
      this.filtering = state?.name?false:true;
    }
  },
  design: {
    isMask: true, //调试模式为false,调试完后会改为true
    fit: true,
  },
});
</script>
