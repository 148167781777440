import proxy from "./proxy";
import proxyTitle from "./proxyTitle";
import textValue from "./textValue";
import dateTime from "./dateTime";
import emptyFilter from "./emptyFilter";
import multipleFiles from "./multipleFiles";
import textValueByLast from "./textValueByLast";
import timeAgo from "./timeAgo";
import htmlImage from "./htmlImage";
import splitArrow from "./splitArrow";
import htmlLink from './htmlLink';
import imgFormat from './imgFormat';
import fileSuffixIcon from './fileSuffixIcon';
import htmlContext from "./htmlContext";

function install(Vue) {
  Vue.use(proxy);
  Vue.use(proxyTitle);
  Vue.use(textValue);
  Vue.use(dateTime);
  Vue.use(emptyFilter);
  Vue.use(multipleFiles);
  Vue.use(textValueByLast);
  Vue.use(timeAgo);
  Vue.use(htmlImage);
  Vue.use(splitArrow);
  Vue.use(htmlLink);
  Vue.use(imgFormat);
  Vue.use(fileSuffixIcon);
  Vue.use(htmlContext)
}

export default install;

export { install, proxy, proxyTitle, textValue, dateTime, emptyFilter, multipleFiles, textValueByLast, timeAgo, htmlImage, splitArrow,htmlContext };
