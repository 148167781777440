<template>
  <div class="ds-control ds-form-item  ds-carnumber" :class="getCss">
    <template v-if="simple&&readOnly&&!isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props"></DsfTextProxy>
      <span v-if="value != ''">{{ append }}</span>
      <slot name="error">
        <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <el-popover class="ds-car-number-popper" v-if="!readOnly" v-model="isExtend" :width="getWidth()" transition="el-zoom-in-top" @hide="hideHandler">
          <template>
            <div ref="keyboard">
              <div class="keyTitle">
              </div>
              <div ref="keyContent" class="keyContent" @click="keyboardClickHandler($event)" @mousedown.prevent.stop>
              </div>
            </div>
          </template>
          <ul ref="input" class="clearfix ul_input" slot="reference">
            <template v-for="index of size">
              <li :key="index" v-if="index<size||(index==size&&isNewEnergy)" class="ds-carnumber-char" :class="{'focus':focusIndex==index}" @click="focusHander(index,$event)">
                <span>
                  {{inputs[index-1]}}
                </span>
              </li>
            </template>
            <li class="new-energy" @click.stop.prevent="newEnergyChangeHander">
              <span>
                <i v-if="!isNewEnergy" class="iconfont icon-hao"></i>
                <i v-else class="iconfont icon-guanbi2"></i>
                <span style="display:block">新能源</span>
              </span>
            </li>
          </ul>
        </el-popover>
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false"></DsfTextProxy>
        </div>
        <slot name="error">
          <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfCarnumber",
  ctrlCaption: "车牌号",
  mixins: [$mixins.formControl],
  props: {
    metadata: {
      type: Object,
      default() {
        let meta = dsf.metadata.get("text-meta-data");
        if (!meta.validate) {
          meta.validate = {
            code: null,
            config: {
              text: {
                errorMsg: {
                  express: "@[expressName]格式不正确",
                  isNumber: "该项必须填写数字",
                  max: "该项长度不可大于@[max]个字符",
                  min: "该项长度不可小于@[min]个字符",
                  numberMax: "该项不可大于@[numberMax]",
                  numberMin: "该项不可小于@[numberMin]",
                  precision: "小数点后最多保留@[precision]位小数",
                  required: "该项必须填写"
                },
                express: "isPlateNumber",
                expressName: "车牌号",
                isNumber: false,
                max: "",
                min: "",
                numberMax: "",
                numberMin: "",
                precision: "",
                required: false
              }
            }
          };
        }
        return meta;
      }
    }
  },
  data() {
    return {
      isNewEnergy: false,
      isExtend: false,
      inputs: [" ", " ", " ", " ", " ", " ", " ", " "],
      focusIndex: 0,
      size: 8,
      width: 450,
      isCheck: false
    };
  },
  computed: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    getWidth() {
      return this.width + 70;
    },
    hideHandler() {
      window.focus;
    },
    blur() {
      this.$nextTick(() => {
        if (this.isCheck) {
          this.emitFormValidate();
        }
        this.isCheck = false;
      });
    },
    newEnergyChangeHander() {
      if (!this.disabled) {
        this.isNewEnergy = !this.isNewEnergy;
        if (!this.isNewEnergy) {
          this.inputs[7] = " ";
          let value = this.inputsToString();
          this.emitValueChange(value);
        }
      }
    },
    focusHander(index, event) {
      if (this.disabled) {
        event.stopPropagation();
        return;
      }
      if (this.isExtend) {
        event.stopPropagation();
      }
      this.focusIndex = index;
    },
    getItemWith() {
      let w = (this.width - 40) / 10; // this.getWidth() / 10;
      return w;
    },
    english(data) {
      this.$refs.keyContent.innerHTML = "";
      let itemWidth = this.getItemWith();
      let englishArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      let english = "";
      english += '<ul class="english" style="width:' + this.width + 'px">';
      for (let i = 0; i < englishArray.length; i++) {
        if (i == 20) {
          english += "<li class='key-item' style='width: " + itemWidth + "px;'>" + englishArray[i] + "</li>";
        } else if (i == 29) {
          english += "<li class='key-item' style='width: " + itemWidth + "px;'>" + englishArray[i] + "</li>";
        } else {
          english += "<li class='key-item' style='width: " + itemWidth + "px'>" + englishArray[i] + "</li>";
        }
      }
      english += "</ul>";
      english += "<div class='tool-keys'>";
      english += '<div class="symbolSwitch">汉字</div>';
      english += '<div class="del" >删除</div>';
      english += "</div>";
      this.$refs.keyContent.innerHTML = english;
    },
    symbol(data) {
      this.$refs.keyContent.innerHTML = "";
      let itemWidth = this.getItemWith();
      let symbolArray = ["京", "津", "渝", "沪", "冀", "晋", "辽", "吉", "黑", "苏", "浙", "皖", "闽", "赣", "鲁", "豫", "鄂", "湘", "粤", "琼", "川", "贵", "云", "陕", "甘", "青", "蒙", "桂", "宁", "新", "藏", "使", "领", "警", "学", "港", "澳", "挂"];
      let english = "";
      english += '<ul class="english" style="width:' + this.width + 'px">';
      for (let i = 0; i < symbolArray.length; i++) {
        if (i == 30) {
          english += "<li class='key-item' style='width: " + itemWidth + "px'>" + symbolArray[i] + "</li>";
        } else {
          english += "<li class='key-item' style='width: " + itemWidth + "px'>" + symbolArray[i] + "</li>";
        }
      }

      english += "</ul>";
      english += "<div class='tool-keys'>";
      english += '<div class="englishKeyboard">英/数</div>';
      english += '<div class="del">删除</div>';
      english += "</div>";
      this.$refs.keyContent.innerHTML = english;
    },
    keyboardOpen() {
      if (this.focusIndex == 1) {
        this.symbol();
      } else {
        this.english();
      }
    },
    keyboardClickHandler(event) {
      let target = event.target;
      if (target.classList.contains("key-item")) {
        let text = event.target.innerText;
        let index = this.focusIndex - 1;
        this.$set(this.inputs, index, text);
        let next = index + 1;
        let size = this.isNewEnergy ? this.size - 1 : this.size - 2;
        if (next <= size) {
          this.focusIndex++;
          this.keyboardOpen();
        }
      } else if (target.classList.contains("englishKeyboard")) {
        this.english();
      } else if (target.classList.contains("symbolSwitch")) {
        this.symbol();
      } else if (target.classList.contains("del")) {
        let currentChat = this.inputs[this.focusIndex - 1];
        if (currentChat.trim()) {
          this.$set(this.inputs, this.focusIndex - 1, " ");
        } else {
          this.focusIndex--;
          if (this.focusIndex < 1) {
            this.focusIndex = 1;
          }
          this.$set(this.inputs, this.focusIndex - 1, " ");
        }
      }
    },
    inputsToString() {
      let v = _.map(this.inputs, (v, i) => {
        if (!this.isNewEnergy) {
          if (i == this.size - 1) {
            return " ";
          }
        }
        return v ? v : " ";
      });
      let code = v.join("");
      let reg = /\s+$/g;
      code = code.replace(reg, "");
      return code;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  watch: {
    inputs: {
      handler(v, ov) {
        let value = this.inputsToString();
        this.emitValueChange(value);
      }
    },
    isExtend: {
      handler(v, ov) {
        if (v) {
          this.keyboardOpen();
        } else {
          this.focusIndex = 0;
          this.blur();
        }
      },
      immediate: false
    },
    value: {
      immediate: true,
      handler(v, ov) {
        if (v != ov) {
          this.isCheck = true;
        }
        if (v) {
          let inputs = v.split("");
          let arr = [];
          if (v.length == 8) {
            this.isNewEnergy = true;
          }
          for (let i = 0; i < this.size; i++) {
            arr[i] = inputs[i] ? inputs[i] : " ";
          }
          this.inputs = arr;
        }
      }
    },
    focusIndex: {
      handler(v, ov) {
        this.keyboardOpen();
      }
    }
  }
});
</script>