export default {
  name: "DsfTextProxy",
  functional: true,
  inject: {
    $vm: {
      default: null
    },
    $page: {
      default: null
    }
  },
  render(h, _vm) {
    var _c = _vm._c || h;
    var injections = _vm.injections;
    // _vm.$page = injections.$page;
    _vm.$vm = injections.$vm;
    _vm.$page = injections?.$vm?.$page;
    let value = _vm.data.model ? _vm.data.model.value : "";
    let domProps = "innerText";
    let currentValueIsHtml = false;

    let icon = _vm?.data.attrs?.textProxyIcon || "";
    let isPre = _vm?.data?.attrs?.isPre || false;
    icon = icon.replace(/^icon-/g, "");
    let labelNode = _vm._e();
    let iconNode = _vm._e();
    if (icon) {
      iconNode = _c("i", {
        staticClass: `proxy-conent proxy-icon iconfont ${"icon-" + icon}`,
        class: [getCode(_vm, "icon")]
      });
    }
    if (_vm.data.attrs.showLabel && _vm.data.attrs.label) {
      labelNode = _c(
        "span",
        {
          staticClass: "proxy-conent proxy-label",
          class: [getCode(_vm, "label")]
        },
        [_vm._v(_vm._s(_vm.data.attrs.label + ":"))]
      );
    }
    let htmlTitle = "";
    //如果值位过滤器开头，则使用innerHTML
    if (_vm.data.attrs.valueIsHtml || _vm.data.attrs.formatter.indexOf("html") == 0) {
      domProps = "innerHTML";
      currentValueIsHtml = true;
    }
    if (currentValueIsHtml) {
      value = dsf.safe.xss(value);
      htmlTitle = createHtmlTitle(value);
    } else {
      htmlTitle = value;
    }
    let valueStr = _vm._s($dataFormat("proxy", _vm.data.attrs.formatter, value));
    //格式化值
    //追加值后缀
    // if (valueStr && _vm?.props?.metadata?.ckey == "text-meta-data" && _vm.props?.append) {
    //   valueStr = valueStr + _vm.props.append;
    // }

    let valueNode = _c("span", {
      staticClass: "proxy-conent",
      class: [getCode(_vm, "value"), isPre ? "ds-text-pre" : ""],
      attrs: {
        title: $dataFormat("proxyTitle", _vm.data.attrs.formatter, htmlTitle)
      },
      domProps: {
        [domProps]: valueStr
      }
    });
    let cls = _vm.data.class;
    if (cls) {
      if (dsf.type(cls) == "string") {
        cls = [cls];
      } else if (dsf.type(cls) == "object") {
        cls = _.map(cls, (v, k) => k);
      }
    } else {
      cls = [];
    }
    let listeners = _vm.listeners;
    let span = _c(
      "span",
      {
        ..._vm.data,
        attrs: {
          id: _vm.data.attrs.id
        },
        class: ["proxy-text", ...(_vm.data.class || []), _vm.data.attrs.visible === false ? "ds-hide" : "", getCode(_vm)],
        on: {
          click(e) {
            clickDefaultHanlder(e, _vm);
          },
          ...listeners
        }
      },
      [iconNode, labelNode, valueNode]
    );
    return span;
  }
};

function clickDefaultHanlder(e, _vm) {
  let grid = $(e.currentTarget).closest(".ds-datagrid");
  if (grid.length > 0) {
    let gridVue = grid.get(0).__vue__;
    if (gridVue) {
      let buttonbarEl = null;
      if (gridVue.operateColumn && gridVue.operateColumn.show) {
        let tbody = grid.find(".el-table__body:eq(0)");
        let tr = tbody.find(`tr:eq(${_vm.props.local.$index})`);
        buttonbarEl = $(tr).find("div[ctrl_type='dsf.buttonbar']");
      } else {
        buttonbarEl = $(gridVue.$el).find("div[ctrl_type='dsf.buttonbar'][row-hash='" + _vm.props.local.$hash + "']");
      }
      if (buttonbarEl && buttonbarEl.length > 0) {
        let buttonbar = buttonbarEl.get(0).__vue__;
        let targetButtons = _.filter(buttonbar.buttons, (btn) => {
          return btn.linkBind == _vm.props.designId;
        });
        targetButtons.length > 0 && buttonbar.triggerButtonClick(targetButtons[0]);
        e.stopPropagation();
        e.preventDefault();
      }
    }
  }
}

function $dataFormat(k, params, v) {
  if (params) {
    let filter = dsf.global.Vue.filter(k);
    if (filter) {
      return filter(v, params);
    }
  }
  return v;
}

function getCode(_vm, suffix) {
  if (_vm) {
    let code = _vm.data.attrs.metadata_fullcode || _vm.data.caption;
    if (code) {
      return code.replace(/\./g, "_") + (suffix ? "__" + suffix : "");
    }
  }
  return "";
}

function getLabelWidth(_vm) {
  if (_vm.$page) {
    return _vm.$page.formItemLabelWidth || _vm.labelWidth;
  }
  return 0;
}

function getLabelWidthStyle(_vm) {
  let labelWidth = getLabelWidth(_vm);
  let showLable = _vm.data.attrs.showLabel;
  if (labelWidth && showLable) {
    return {
      width: labelWidth + "px"
    };
  }
  return {};
}

function getFormItemBlockMarginLeft(_vm) {
  let labelWidth = getLabelWidth(_vm);
  let showLable = _vm.data.attrs.showLabel;
  if (labelWidth && showLable) {
    return {
      "margin-left": labelWidth + "px"
    };
  }
  return {};
}

function createHtmlTitle(html) {
  let div = document.createElement("div");
  div.innerHTML = html;
  return div.innerText;
}
