<template>
  <div class="dsf-forgot-password-control">
    <!-- 顶部步骤导航 -->
    <div class="dsf-forgot-password-step">
      <div class="forgot-password-step" :class="{ 'current-step': step >= 1 }">
        <div class="step-cricle">
          <div class="cricle normal">1</div>
          <p v-if="forget_pwd_setting === 'phone'">手机验证</p>
          <p v-else-if="forget_pwd_setting === 'mail'">邮箱验证</p>
          <p v-else>手机、邮箱验证</p>
        </div>
        <div class="right-line"></div>
      </div>
      <div class="forgot-password-step" :class="{ 'current-step': step >= 2 }">
        <div class="left-line"></div>
        <div class="step-cricle">
          <div class="cricle">2</div>
          <p>设置操作</p>
        </div>
        <div class="right-line"></div>
      </div>
      <div class="forgot-password-step" :class="{ 'current-step': step >= 3 }">
        <div class="left-line"></div>
        <div class="step-cricle">
          <div class="cricle">3</div>
          <p>完成</p>
        </div>
      </div>
    </div>

    <!-- 手机、邮箱切换 -->
    <div class="dsf-forgot-password-tab" v-if="forget_pwd_setting === 'all' && step === 1">
      <div
        class="dsf-forgot-password-tab-item"
        :class="{ 'password-tab-item-active': tabActive === 0 }"
        @click="tabClick(0)"
      >
        <p>手机号验证</p>
      </div>
      <div
        class="dsf-forgot-password-tab-item"
        :class="{ 'password-tab-item-active': tabActive === 1 }"
        @click="tabClick(1)"
      >
        <p>邮箱验证</p>
      </div>
    </div>

    <!-- 内容部分 -->
    <div class="dsf-forgot-password-content">
      <template v-if="forget_pwd_setting === 'phone' || (forget_pwd_setting === 'all' && tabActive === 0)">
        <!-- 第一步 -->
        <div v-if="step == 1">
          <input
            type="text"
            v-model="phone"
            maxlength="11"
            class="dsf-forgot-password-input"
            placeholder="请输入手机号"
          />
          <div class="dsf-forgot-password-btn" @click="showDialog">
            请点击进行验证
          </div>
          <el-button
            type="primary"
            class="dsf-forgot-password-next-btn"
            :class="{ disabled: disabled }"
            :disabled="disabled"
            @click="checkEmailCode"
          >
            下一步
          </el-button>
        </div>
      </template>
      <template v-else>
        <!-- 第一步 -->
        <div v-if="step == 1" class="dsf-forgot-password-email">
          <div class="dsf-forgot-password-input-e">
            <input
              type="text"
              v-model="email"
              class="dsf-forgot-password-input"
              placeholder="请输入邮箱地址"
            />
            <p @click="sendEmailCode">{{ mailTimeMsg }}</p>
          </div>
          <input
            type="text"
            v-model="emailcode"
            class="dsf-forgot-password-input"
            placeholder="请输入邮箱验证码"
          />
          <p class="error-msg" v-if="codeAuthResult && emailcode">
            <i class="iconfont icon-tixingshixin" />
            {{codeAuthResult}}
          </p>
          <el-button
            type="primary"
            class="dsf-forgot-password-next-btn"
            :class="{ disabled: disabled }"
            :disabled="disabled"
            @click="checkEmailCode"
          >
            下一步
          </el-button>
        </div>
      </template>
      <!-- 第二步 -->
      <div v-if="step === 2">
        <p class="step-title">设置新密码</p>
        <div class="dsf-forgot-password-input-btn">
          <input :type="type" v-model="newpwd" placeholder="设置新密码" />
          <i
            class="icon iconfont"
            :class="{
              'icon-icon-invisible': type === 'password',
              'icon-icon-visible': type === 'text',
            }"
            @click="changeIcon"
          ></i>
        </div>
        <div class="dsf-forgot-password-input-btn">
          <input type="password" v-model="confirmNewpwd" placeholder="确认新密码" />
        </div>
        <p class="step-tip">{{ notice }}</p>
        <el-button
          type="primary"
          @click="nextStep(3)"
          class="dsf-forgot-password-next-btn"
          :class="{ disabled: !newpwd||(newpwd&&newpwd!==confirmNewpwd)}"
          :disabled="!newpwd||(newpwd&&newpwd!==confirmNewpwd)"
        >
          下一步
        </el-button>
      </div>
      <!-- 第三步 -->
      <div class="step-three" v-if="step === 3">
        <div class="step-succss">
          <i class="el-icon-check" />
        </div>
        <p class="success-tip">登录密码更新成功</p>
        <el-button
          @click="reLogin"
          type="primary"
          class="dsf-forgot-password-success-btn"
        >
          确 定
        </el-button>
      </div>
    </div>
    <el-dialog :modal-append-to-body="false" :visible.sync="dialogVisible" width="480px" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="forgot-password-dialog">
        <div class="title">手机短信验证码</div>
        <div class="phone">当前手机号：{{ hiddenPhone }}</div>
        <div class="dsf-forgot-password-input-2">
          <input type="text" v-model="code" placeholder="请输入验证码" />
          <el-button
            type="primary"
            :class="{ disabled: interval }"
            :disabled="Boolean(interval)"
            @click="getCode"
            class="dsf-forgot-password-next-btn"
          >
            {{ timeMsg }}
          </el-button>
        </div>
        <p class="error-msg" v-if="codeAuthResult && code">
          <i class="iconfont icon-tixingshixin" />
          {{codeAuthResult}}
        </p>
        <el-button
          type="primary"
          :class="{ disabled: !code }"
          @click="nextStep(2)"
          :disabled="!code"
          class="dsf-forgot-password-dialog-next-btn"
        >
          下一步
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfPlatformForgotPassword",
  ctrlCaption: "忘记密码",
  mixins: [$mixins.control],
  data() {
    return {
      // 步骤
      step: 1,
      //手机号码
      phone: "",
      // 邮箱地址
      email: "",
      // 邮箱验证码
      emailcode: "",
      disabled: true,
      // 弹框显示
      dialogVisible: false,
      // 倒计时时间
      time: this.countdownTimes,
      //倒计时时间
      countdownTimes: 0,
      // 控制密码框是否显示
      type: "password",
      // 新设置密码
      newpwd: "",
      //确认新密码
      confirmNewpwd:'',
      //新密码提示
      notice:'',
      // 短信倒计时定时器
      interval: null,
      // 短信验证码
      code: "",
      // 是否已发送验证码
      isCodeSend: false,
      //验证码验证结果
      codeAuthResult: '',
      //验证码token
      token:'',
      // 忘记密码模式   phone 手机号码验证   mail 邮箱验证   all 手机、邮箱同时验证
      forget_pwd_setting: "",
      // 忘记密码tab当前显示
      tabActive: 0,
      // 是否已验证手机
      checkPhone: false
    };
  },
  props: {
    title:{
      type:String,
      default:'忘记密码'
    },
    intervalUrl:{
      type:String,
      default:'oua2/user/pwd/send/interval'
    },
    getCodeUrl:{
      type:String,
      default:'oua2/user/pwd/send'
    },
    authCodeUrl:{
      type:String,
      default:'oua2/user/pwd/token'
    },
    alterPwdUrl:{
      type:String,
      default:'oua2/user/pwd/update'
    },
    authUrl:{
      type:String,
      default:'oua2/user/pwd/verify'
    },
    sendUpdatePwdEmail:{
      type:String,
      default:'password/sendUpdatePwdEmail'
    },
    checkUpdatePwdEmail:{
      type:String,
      default:'password/checkUpdatePwdEmail'
    },
    checkStrengthPwdByEmail:{
      type:String,
      default:'password/checkStrengthPwdByEmail'
    },
    updatePwdByEmail:{
      type:String,
      default:'password/updatePwdByEmail'
    },
  },
  computed: {
    timeMsg() {
      return this.interval
        ? this.time + " s": (this.isCodeSend? "重新获取": "获取短信验证码");
    },
    mailTimeMsg() {
      return this.interval
        ? this.time + " s": (this.isCodeSend? "重新获取": "发送验证码");
    },
    hiddenPhone() {
      return this.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
  },
  watch: {
    code(){
      this.codeAuthResult = '';
    },
    step(val) {
      if (val === 2) {
        this.dialogVisible = false;
      }
    },
    dialogVisible(val) {
      if (!val) {
        this.interval && clearInterval(this.interval);
        this.time = this.countdownTimes;
        this.interval = null;
      }
    },
    emailcode(val) {
      this.codeAuthResult = '';
      if (val != "" && this.disabled) {
        this.disabled = false;
      } else if (val === "" && !this.disabled) {
        this.disabled = true;
      }
    }
  },
  created() {
    console.log(dsf?.config?.setting_public_forget_pwd_setting);
    this.forget_pwd_setting = dsf?.config?.setting_public_forget_pwd_setting || "all";
  },
  mounted() {
    if(!this.isDesign){
      // 获取倒计时
      this.getInterval();
    }
  
  },
  beforeDestroy () {
    this.interval && clearInterval(this.interval);
    this.time = this.countdownTimes;
    this.interval = null;
  },
  methods: {
    tabClick(tabindex) {
      if (
        this.forget_pwd_setting === "all" &&
        tabindex === 1 &&
        !this.checkPhone
      ) {
        dsf.layer.message("请先验证手机！", false);
        return false;
      }
      this.tabActive = tabindex;
    },
    // 获取倒计时
    getInterval() {
      this.$http
        .get(this.intervalUrl, {})
        .done(({ success, data, message }) => {
          if (success) {
            this.countdownTimes = data.interval;
            this.time = data.interval;
            this.notice = data.notice
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error((err) => {
          dsf.error(err);
          dsf.layer.message(err?.message || "请求异常", false);
        })
        .always(() => {
        });
    },
    showDialog() {
      if(this.phone===''){
        dsf.layer.message("手机号不能为空", false);
        return false
      }
      if (/^1(3|4|5|7|8|9|6)\d{9}$/.test(this.phone)) {
        this.codeAuthResult=''
        this.code=''
        this.dialogVisible = true;
      } else {
        dsf.layer.message("手机格式不正确", false);
      }
    },
    async sendEmailCode() {
      if (this.interval) return false;
      if(this.email === ""){
        dsf.layer.message("邮箱地址不能为空", false);
      } else {
        if (/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.email)) {
          let loader = dsf.layer.loading();
          await this.$http
            .post(this.sendUpdatePwdEmail, { email: this.email })
            .done(({ success, data, message }) => {
              if (success) {
                dsf.layer.message("发送成功");
                this.interval = setInterval(() => {
                  this.time--;
                  if (this.time <= 0) {
                    clearInterval(this.interval);
                    this.interval = null;
                    this.time = this.countdownTimes;
                  }
                }, 1000);
              } else {
                dsf.layer.message(message || "请求异常", false);
              }
            })
            .error((err) => {
              dsf.error(err);
              dsf.layer.message(err?.message || "请求异常", false);
            })
            .always(() => {});
          dsf.layer.closeLoading(loader);
        } else {
          dsf.layer.message("邮箱格式不正确", false);
        }
      }
    },
    checkEmailCode() {
      if (this.forget_pwd_setting === "phone") {
        this.step = 2;
      } else {
        if (this.email && this.emailcode) {
          this.$http
            .get(this.checkUpdatePwdEmail, { code: this.emailcode, email: this.email })
            .done(({ success, data, message }) => {
              if (success) {
                this.token = data;
                this.step = 2;
              } else {
                this.codeAuthResult = "验证码错误";
                // dsf.layer.message(message || "请求异常", false);
              }
            })
            .error((err) => {
              dsf.error(err);
              dsf.layer.message(err?.message || "请求异常", false);
            })
            .always(() => {});
        } else if (this.email === "") {
          dsf.layer.message("邮箱地址不能为空", false);
        }
      }
    },
    changeIcon() {
      if (this.type === "text") {
        this.type = "password";
      } else {
        this.type = "text";
      }
    },
    async nextStep(step) {
      if(step===2){
        // 验证验证码值
        let {err,token} = await this.authCode()
        console.log(err);
        if(err){
          this.codeAuthResult = err
          return false
        }

        if (this.forget_pwd_setting === "all") {
          this.checkPhone = true;
          this.dialogVisible = false;
          this.tabActive = 1;
        } else {
          this.token = token
          this.step = step;
        }
      }else if (step === 3) {
        if (this.forget_pwd_setting === 'phone' || (this.forget_pwd_setting === 'all' && this.tabActive === 0)) {
          this.authPwd(step)
        } else {
          let loader = dsf.layer.loading();
          // this.$http
          //   .get(this.checkStrengthPwdByEmail, { token: this.token, param: dsf.sm2.encode(this.newpwd,dsf.config.setting_public_smpublickey) })
          //   .done(async ({ success, message }) => {
          //     if (success) {
          this.$http
            .post(this.updatePwdByEmail, { token: this.token, param: dsf.sm2.encode(this.newpwd,dsf.config.setting_public_smpublickey) })
            .done(({ success, data, message }) => {
              dsf.layer.closeLoading(loader);
              if (success) {
                this.step = step;
              } else {
                dsf.layer.message(message || "请求异常", false);
              }
            })
            .error((err) => {
              dsf.layer.closeLoading(loader);
              dsf.error(err);
              dsf.layer.message(err?.message || "请求异常", false);
            });
          // } else {
          //   dsf.layer.closeLoading(loader);
          //   dsf.layer.message(message, false);
          // }
          // })
        }
      }
    },
    // 获取验证码
    getCode() {
      this.$http
        .post(this.getCodeUrl, { phone: this.phone })
        .done(({ success, data, message }) => {
          if (success) {
            this.isCodeSend = true;
            dsf.layer.message("发送成功");
            this.interval = setInterval(() => {
              this.time--;
              if (this.time <= 0) {
                clearInterval(this.interval);
                this.interval = null;
                this.time = this.countdownTimes;
              }
            }, 1000);
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error((err) => {
          dsf.error(err);
          dsf.layer.message(err?.message || "请求异常", false);
        })
        .always(() => {});
    },
    // 验证验证码
    authCode(){
      return new Promise((resolve)=>{
        this.$http
          .get(this.authCodeUrl, { phone: this.phone,code:this.code })
          .done(({ success, data, message }) => {
            if (success) {
              resolve({err:null,token:data})
            } else {
              resolve({err: message || '请求异常',token:null})
            }
          })
          .error((err) => {
            resolve({err:err?.message||'请求异常',token:null})
          })
          .always(() => {});
      })
    },
    // 校验密码
    async authPwd(step) {
      let {err} = await this.alterPassword()
      if(err) return dsf.layer.message(err, false);
      this.step = step;
      // this.$http
      //   .get(this.authUrl, { phone: this.phone,token:this.token,param:this.newpwd })
      //   .done(async ({ success, message }) => {
      //     if (success) {
      //       let {err} = await this.alterPassword()
      //       if(err) return dsf.layer.message(err, false);
      //       this.step = step;
      //     } else {
      //       dsf.layer.message(message, false);
      //     }
      //   })
    },
    // 修改密码
    alterPassword(){
      return new Promise((resolve)=>{
        this.$http
          .post(this.alterPwdUrl, { phone: this.phone,token:this.token, param: dsf.sm2.encode(this.newpwd,dsf.config.setting_public_smpublickey)})
          .done(({ success, data, message }) => {
            if (success) {
              resolve({err:null,token:data})
            } else {
              resolve({err:message||'请求异常',token:null})
            }
          })
          .error((err) => {
            resolve({err:err?.message||'请求异常',token:null})
          })
          .always(() => {});
      })
    },
    reLogin() {
      if(this.$listeners['complete']){
        this.$dispatch('complete')
      }else {
        this.$router.go(-1)
      }
    },
  },
});
</script>
