var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ds-control",style:(_vm.controlStyle)},[_c('div',{staticClass:"ds-flex-column",style:({
      padding: _vm.padding.join('px ') + 'px',
    }),attrs:{"isDesign":_vm.isDesign}},_vm._l((_vm.slots),function(item,index){return _c('div',{key:index,staticClass:"ds-flex-column-row",style:({
        width: item.width,
        height: !_vm.isDesign && (item.flexShrink ? '0' : item.height),
        flex: !_vm.isDesign && (item.flexShrink ? '1 1 auto' : '0 0 auto'),
        marginTop: _vm.gutter && index > 0 ? (_vm.gutter + "px") : '0',
      }),attrs:{"slot-name":item.name}},[_vm._t(item.name)],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }