<template>
  <div v-loading="loading" class="dsf-control ds-nav-menu-setting">
    <div class="left">
      <div class="title">可选菜单</div>
      <dsf-virtual-scroll height="0" style="flex: auto" scroll-y>
        <div v-if="!menuList.length" class="no-data">暂时还没有数据~</div>
        <template v-for="group1 in menuList">
          <div
            v-if="group1.children && group1.children.length > 0"
            :key="group1._id"
            class="group"
          >
            <div class="group-title">
              <span>{{ group1._name }}</span>
            </div>
            <div class="group-content">
              <template v-for="group2 in group1.children">
                <el-checkbox
                  v-if="!group2.children"
                  :key="group2._id"
                  :value="checkList.indexOf(group2._id) > -1"
                  class="menu-item"
                  :title="group2._name"
                  @change="selectChange(group2, $event)"
                >
                  <div class="ellipsis">{{ group2._name }}</div>
                </el-checkbox>
                <template v-for="menu in group2.children">
                  <el-checkbox
                    :key="menu._id"
                    :value="checkList.indexOf(menu._id) > -1"
                    class="menu-item"
                    :title="menu._name"
                    @change="selectChange(menu, $event)"
                  >
                    <div class="ellipsis">{{ menu._name }}</div>
                  </el-checkbox>
                </template>
              </template>
            </div>
          </div>
        </template>
      </dsf-virtual-scroll>
    </div>
    <div ref="right" class="right">
      <div class="title">
        已选菜单
        <span v-show="selectMenus.length">({{ selectMenus.length }})</span>
        <span class="tips">拖动菜单可排序</span>
      </div>
      <dsf-virtual-scroll height="0" style="flex: auto" scroll-y>
        <div
          v-if="activeDom"
          ref="move"
          class="menu-item"
          :class="{ down: showEffect }"
          v-html="dsf.safe.xss(activeDom)"
          :style="moveDownStyle"
        />
        <div v-if="!selectMenus.length" class="no-data">请在左侧选择菜单</div>
        <div :style="{ height: selectMenus.length * 39 + 'px' }">
          <template v-for="(menu, index) in selectMenus">
            <div
              :key="menu.id"
              ref="menu"
              v-show="!activeMenu || activeMenu.id != menu.id"
              class="menu-item"
              :style="{ transform: 'translateY(' + 39 * menu.order + 'px)' }"
              :index="index"
              @mousedown="mousedown(menu, index, $event)"
            >
              <i class="icon iconfont" :class="[menu.scicon]"></i>
              <span>{{ menu.name }}</span>
              <i
                class="closeBt iconfont icon-guanbi2"
                @mousedown.stop
                @click.stop="remove(index)"
              ></i>
            </div>
          </template>
        </div>
      </dsf-virtual-scroll>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNavMenuSetting",
  mixins: [$mixins.control],
  ctrlCaption: "菜单定制",
  props: {
    value: {
      type: Array,
      required: false,
    },
    allUrl: {
      type: String,
      default: "/scmenu/queryUserAllowMarkScMenu"
    }
  },
  data() {
    return {
      loading: false,
      isDown: false,
      isMove: false,
      menuList: [],
      checkList: [],
      selectMenus: [],
      showEffect: false,
      activeDom: null,
      activeTop: 0,
      activeMenu: null,
    };
  },
  computed: {
    moveDownStyle() {
      return {
        transform: "translateY(" + this.activeTop + "px)",
      };
    },
  },
  created() {
    if (!this.isDesign) {
      this.postData();
      this.init();
    }
  },
  mounted() {
    window.addEventListener("mousemove", this.mousemove);
    window.addEventListener("mouseup", this.mouseup);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mousemove);
    window.removeEventListener("mouseup", this.mouseup);
  },
  methods: {
    yes() {
      return _.cloneDeep(this.selectMenus);
    },
    init() {
      if (this.value && this.value.length) {
        let checkList = [];
        this.selectMenus = this.value.map(({ id, name, order, icon }, i) => {
          checkList.push(id);
          return {
            id: id,
            scicon: icon,
            order: i,
            name: name,
          };
        });
        this.checkList = checkList;
      } else {
        this.selectMenus = [];
        this.checkList = [];
      }
    },
    postData() {
      this.loading = true;
      this.$http
        .get(this.$replace(this.allUrl, this))
        .done(({ success, data, message }) => {
          if (success) {
            this.menuList = data;
          } else {
            dsf.layer.message(message, false);
          }
        })
        .error((err) => {
          dsf.error(err);
          dsf.layer.message(err.message || "请求异常", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectChange({ _id, name, _name, icon }, e) {
      if (e) {
        this.checkList.push(_id);
        this.selectMenus.push({
          id: _id,
          scicon: icon,
          order: this.checkList.length - 1,
          name: name || _name,
        });
      } else {
        let index = this.checkList.indexOf(_id);
        this.remove(index);
      }
    },
    remove(index) {
      let _id = this.checkList.splice(index, 1);
      let o = null;
      dsf.array.remove(this.selectMenus, function (v) {
        if (v.id == _id) {
          o = v.order;
          return true;
        }
        return false;
      });
      if (o !== null) {
        for (let i = 0; i < this.selectMenus.length; i++) {
          let item = this.selectMenus[i];
          let order = item.order;
          if (order > o) {
            this.$set(item, "order", order - 1);
          }
        }
      }
    },
    mousedown(menu, index, event) {
      const e = event || window.event;
      this.isDown = true;
      let menuDom = this.$refs.menu[index];
      this.activeDom = menuDom.innerHTML;
      this.activeTop = 39 * menu.order;
      this.activeMenu = menu;
      this._lastScaleY = e.screenY;
      setTimeout(() => {
        this.showEffect = true;
      }, 50);
    },
    mouseup() {
      this.isDown = false;
      this.isMove = false;
      this.activeDom = null;
      this.showEffect = false;
      this.activeMenu = null;
    },
    mousemove(event) {
      if (!this.isDown || !this.activeMenu) {
        return;
      }
      const e = event || window.event;
      this.isMove = true;
      let scaleY = e.screenY;
      this.activeTop += scaleY - this._lastScaleY;
      this._lastScaleY = scaleY;
      let selectMenus = this.selectMenus;
      let oldIndex = this.activeMenu.order;
      let newIndex = Math.floor((this.activeTop + 20) / 39);
      newIndex = newIndex < 0 ? 0 : newIndex;
      newIndex =
        newIndex >= selectMenus.length ? selectMenus.length - 1 : newIndex;
      if (newIndex == oldIndex) return;
      // 上移
      if (newIndex < oldIndex) {
        for (let i = 0; i < this.selectMenus.length; i++) {
          let item = this.selectMenus[i];
          let order = item.order;
          if (order >= newIndex && order < oldIndex) {
            this.$set(item, "order", order + 1);
          }
        }
      }
      // 下移
      else {
        for (let i = 0; i < this.selectMenus.length; i++) {
          let item = this.selectMenus[i];
          let order = item.order;
          if (order >= oldIndex && order <= newIndex) {
            this.$set(item, "order", order - 1);
          }
        }
      }
      this.$set(this.activeMenu, "order", newIndex);
    },
  },
});
</script>
