<template>
  <div class="ds-login-page">
    <div class="login-header">
      <img
        ref="logo"
        v-if="logo"
        class="logo"
        :src="logo | imgFormat"
        :alt="title"
      />
      <div
        v-if="title"
        class="site-name"
      >{{ title }}</div>
    </div>
    <div
      class="login-section"
      :style="{ 'background-image': carouselItem ? '' : 'url(' + backgroundImage + ')' }"
    >
      <div class="login-box">
        <el-form @submit.native.prevent>
          <slot>
            <slot name="form">
              <div class="login-box-title">登 录</div>
              <div class="login-box-info">
                <el-input
                  v-model.trim="username"
                  :disabled="loading"
                  :placeholder="userNamePlaceholder"
                  size="medium"
                  prefix-icon="el-icon-user-solid"
                >
                </el-input>
              </div>
              <div class="login-box-info">
                <el-input
                  v-model.trim="password"
                  :disabled="loading"
                  type="password"
                  :placeholder="passwordPlaceholder"
                  size="medium"
                  prefix-icon="el-icon-s-goods"
                />
              </div>
              <div
                class="login-box-info login-code"
                v-if="isShowCode"
              >
                <el-input
                  v-model.trim="code"
                  :disabled="loading"
                  type="text"
                  :placeholder="codePlaceholder"
                  size="medium"
                  prefix-icon="iconfont icon-yanzhengma1"
                />
                <img
                  class="img-code"
                  :src="codeUrl"
                  @click="updateCodeUrl"
                />
              </div>
              <div class="login-box-info">
                <el-checkbox v-model="remember">记住密码</el-checkbox>
              </div>
              <div class="login-box-button">
                <el-button
                  :loading="loading"
                  type="info"
                  native-type="submit"
                  size="small"
                  @click="submit"
                >登 录</el-button>
              </div>
              <div
                class="login-box-forgot-password"
                v-if="isForgotPassword"
              >
                <span @click="toForgotPassword">忘记密码?</span>
              </div>
            </slot>
          </slot>
        </el-form>
      </div>
      <el-carousel
        v-if="carouselItem"
        class="login-carousel"
        :interval="carouselInterval"
      >
        <el-carousel-item
          v-for="item in carouselItem"
          :key="item"
        >
          <img :src="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="login-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DsfPlatformLogin',
  data() {
    return {
      loading: false,
      username: '',
      password: '',
      code: '',
      remember: false,
      rememberToken: null,
      carouselHeight: 0,
      carouselInterval:
        Number(dsf.config.setting_public_carousel_interval) || 3000,
      // 图片验证码
      codeUrl: '',
      oldPsd: '',
    }
  },
  props: {
    logo: {
      type: String,
      default() {
        return dsf.config.setting_public_login_logo
      },
    },
    title: {
      type: String,
      default() {
        return dsf.config.setting_public_login_title
      },
    },
    isForgotPassword: {
      type: Boolean,
      default: true,
    },
    isShowCode: {
      type: Boolean,
      default() {
        return dsf.config.setting_public_verification_code
      },
    },
    login: {
      type: Function,
      default: null,
    },
    userNamePlaceholder: {
      type: String,
      default: '账户名称',
    },
    passwordPlaceholder: {
      type: String,
      default: '密码',
    },
    codePlaceholder: {
      type: String,
      default: '请输入验证码',
    },
    // 图片验证码url
    imgCodeUrl: {
      type: String,
      default: '/code/image?key=pc_login&t=@[now().getTime()]',
    },
    backgroundImage: {
      type: String,
      default() {
        return (
          dsf.config.setting_public_login_bg ||
          require('../../assets/styles/themes/img/loginBg.png')
        )
      },
    },
    customSetStateIsLogin: {
      type: Function,
      default() {
        return false
      },
    },
  },
  created() {
    this.rememberToken = dsf.storage.local.get('rememberToken')
    if (_.isObject(this.rememberToken)) {
      let u = Object.keys(this.rememberToken)[0]
      let p = Object.values(this.rememberToken)[0]
      this.username = u
      this.password = p
      this.oldPsd = p
      this.remember = true
    }
    if (this.isShowCode) {
      this.updateCodeUrl()
    }
  },
  computed: {
    carouselItem() {
      let picture = dsf.config.setting_public_carousel_picture
      let item = picture != '' && dsf.isDef(picture) && picture.split(',')
      return item && item.map((v) => dsf.url.getWebPath(v))
    },
  },
  methods: {
    toForgotPassword() {
      // location.replace(dsf.config.setting_public_forgot_password_page)
      location.href = dsf.config.setting_public_forgot_password_page
    },
    // 图片验证码
    updateCodeUrl() {
      this.codeUrl = dsf.url.getWebPath(this.$replace(this.imgCodeUrl))
    },
    submit() {
      dsf.cookies.remove('authorization_token')
      if (!this.login) {
        this.username = _.trim(this.username)
        this.password = _.trim(this.password)
        this.code = _.trim(this.code)
        if (this.username === '') {
          dsf.layer.message('账号不能为空！', false)
          return
        }
        if (this.password === '') {
          dsf.layer.message('密码不能为空！', false)
          return
        }
        if (this.isShowCode && this.code === '') {
          dsf.layer.message('验证码不能为空！', false)
          return
        }
        this.loginHandler()
      } else if (dsf.type(this.login) == 'function') {
        this.login({
          username: this.username,
          password: this.password,
          remember: this.remember,
          code: this.code,
        })
          .then((res) => {
            if (res && res.success && res.state == 20000) {
              this.setStateIsLogin(res)
            }
          })
          .catch((ex) => {
            dsf.warn('登录不成功')
          })
      }
    },
    loginHandler() {
      this.loading = true
      this.$webAPI
        .login({
          userName: this.username,
          password: dsf.md5(dsf.md5(this.password)),
          remember: this.remember,
          rememberToken: dsf.md5(dsf.md5(this.oldPsd)),
          code: this.code || 'good',
        })
        .then(({ data, success, message, state }) => {
          if (success) {
            data.rememberToken
              ? dsf.storage.local.set('rememberToken', {
                [this.username]: data.rememberToken,
              })
              : dsf.storage.local.remove('rememberToken')
            this.setStateIsLogin(data)
          } else if (state == 20555) {
            // token过期
            this.password = ''
            this.code = ''
            dsf.layer.message(message, false)
          } else {
            this.updateCodeUrl()
            dsf.layer.message(message, false)
          }
        })
        .catch((err) => {
          this.updateCodeUrl()
          if (err) {
            dsf.layer.message(err.message || '未知错误', false)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 储存cookie登录状态
    setStateIsLogin(data) {
      dsf.cookies.set('isLogin', 1)
      let from = this.$route.query.from
      let _form = this.customSetStateIsLogin(data)
      if (from) {
        from = decodeURIComponent(from)
      } else if (_form) {
        from = _form
      } else {
        from = dsf.config.setting_public_login_success_to
      }
      location.replace(from)
    },
  },
}
</script>
