var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dsf-home-tab-panel',_vm._b({ref:"panel",staticClass:"ds-schedule-box",attrs:{"slots":_vm.realConfig,"add-show":"","more-show":"","setting-show":""},on:{"setting-click":_vm.settingClick}},'dsf-home-tab-panel',_vm.panelConfig,false),[_vm._l((_vm.realConfig),function(config){return _c('template',{slot:config.name},[_c('dsf-schedule-list-item',{key:config.name,attrs:{"url":config.url,"is-design":_vm.isDesign,"is-switch":_vm.isSwitch,"setting-config":_vm.settingConfig,"classInfo":"","is-parameter":""},scopedSlots:_vm._u([{key:"list",fn:function(ref){
var list = ref.list;
return [_vm._t("list",null,{"list":list})]}},{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._t("default",null,{"item":item,"index":index})]}},{key:"list-bottom",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._t(("list-bottom-" + (config.name)),null,{"config":config,"item":item,"index":index})]}}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }